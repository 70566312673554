import React, { useEffect } from "react";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import FilterWhat from "./FilterWhat";
import FilterWhere from "./FilterWhere";
import FilterDates from "./FilterDates";
import FindJobsBtn from "./FindJobsBtn";

const SearchForm = ({ filters, setFilters, findJobs }) => {

  useEffect(() => {
    console.log(filters);
  }, [filters]);

  const handleTyping = event => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleDatesChange = selection => {
    setFilters({
      ...filters,
      startDate: selection.startDate,
      endDate: selection.endDate,
    });
  };

  const handleSubmitForm = event => {
    event.preventDefault();
    findJobs(true);
  };

  return (
    <section className='findJobs__inputsBox'>
      <form onSubmit={handleSubmitForm}>
        <FilterWhat text={filters.what} onChange={handleTyping} />
        <FilterWhere text={filters.where} onChange={handleTyping} />
        <FilterDates startDate={filters.startDate} endDate={filters.endDate} onDateChange={handleDatesChange} />

        <FindJobsBtn />

      </form>
    </section>
  );
};

export default SearchForm;
