import axios from "axios";
import useHandleErrors from "./useHandleErrors";
import { USER_STORAGE_KEY } from "../config/settings";
import { useNavigate } from "react-router";

const useAuthentication = () => {
  const { handleErrors } = useHandleErrors();
  const navigate = useNavigate();

  const authenticate = () => {
    let user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));
    return user;
  };

  const login = async data => {
    try {
      const response = await axios.post("/api/users/signin", data);
      localStorage.setItem(
        USER_STORAGE_KEY,
        JSON.stringify({ ...response.data })
      );
      navigate("/");
    } catch (err) {
      handleErrors(err);
    }
  };

  const register = async data => {
    try {
      await axios.post("/api/users/register", data);
      alert("User registered successfully!");
      navigate("/signin");
    } catch (err) {
      handleErrors(err);
    }
  };

  return { authenticate, login, register };
};

export default useAuthentication;
