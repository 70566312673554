import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { IconButton } from "@mui/material";

const PageInfo = ({ pagination }) => {
  return (
    <section className='jobsPager'>
      <div className='jobsPager__left'></div>
      <div className='jobsPager__right'>
        <span>
          Page {pagination.page} of{" "}
          {Math.ceil(pagination.total / pagination.limit)}
        </span>
        <span>
          <IconButton>
            <HelpOutlineIcon sx={{ fontSize: "1rem", color: "lightgray" }} />
          </IconButton>
        </span>
      </div>
    </section>
  );
};

export default PageInfo;
