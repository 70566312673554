import { useNavigate } from "react-router";
import { USER_STORAGE_KEY } from "../config/settings";

const useHandleErrors = () => {
  const navigate = useNavigate();

  const handleErrors = err => {
    // console.log(err)
    err?.response && alert(err?.response?.data[0]?.message);
    if (err?.response && err?.response?.status === 401) {
      localStorage.removeItem(USER_STORAGE_KEY);
      navigate("/signin");
    }
  };

  return { handleErrors };
};

export default useHandleErrors;
