import axios from "axios";
import useHandleErrors from "./useHandleErrors";


const useJobsAPI = () => {
  const { handleErrors } = useHandleErrors();

  const apiFetchJobs = async (filters, pagination) => {
    const { what, where, startDate, endDate } = filters;
    const { skip, limit } = pagination;

    try {
      let searchJobUrl = `/api/jobs/search?what=${what}&where=${where}&startDate=${startDate}&endDate=${endDate}&skip=${skip}&limit=${limit}`;
      const response = await axios.get(searchJobUrl);
      console.log(response.data);

      return response.data;

    } catch (err) {
      handleErrors(err);
    }
  };

  const apiFetchJobById = async jobId => {
    try {
      let detailJobUrl = `/api/jobs/${jobId}`;
      const response = await axios.get(detailJobUrl);
      console.log(response.data);

      return response.data;

    } catch (err) {
      handleErrors(err);
    }
  };

  return { apiFetchJobs, apiFetchJobById };
};

export default useJobsAPI;
