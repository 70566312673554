export const LOCAL_API_URL = "http://localhost:1337";
export const PROD_API_URL = "https://api.staging.jobssil.xyz";

export const USER_STORAGE_KEY = "JOBSILUSER";

export const states = [
  { state: "Alaska", abr: "AK" },
  { state: "Louisiana", abr: "LA" },
  { state: "Oklahoma", abr: "OK" },
  { state: "Arizona", abr: "AZ" },
  { state: "Maine", abr: "ME" },
  { state: "Oregon", abr: "OR" },
  { state: "Arkansas", abr: "AR" },
  { state: "Maryland", abr: "MD" },
  { state: "Pennsylvania", abr: "PA" },
  { state: "American Samoa", abr: "AS" },
  { state: "Massachusetts", abr: "MA" },
  { state: "Puerto Rico", abr: "PR" },
  { state: "California", abr: "CA" },
  { state: "Michigan", abr: "MI" },
  { state: "Rhode Island", abr: "RI" },
  { state: "Colorado", abr: "CO" },
  { state: "Minnesota", abr: "MN" },
  { state: "South Carolina", abr: "SC" },
  { state: "Connecticut", abr: "CT" },
  { state: "Mississippi", abr: "MS" },
  { state: "South Dakota", abr: "SD" },
  { state: "Delaware", abr: "DE" },
  { state: "Missouri", abr: "MO" },
  { state: "Tennessee", abr: "TN" },
  { state: "District of Columbia", abr: "DC" },
  { state: "Montana", abr: "MT" },
  { state: "Texas", abr: "TX" },
  { state: "Florida", abr: "FL" },
  { state: "Nebraska", abr: "NE" },
  { state: "Trust Territories", abr: "TT" },
  { state: "Georgia", abr: "GA" },
  { state: "Nevada", abr: " NV" },
  { state: "Utah", abr: "UT" },
  { state: "Guam", abr: " GU" },
  { state: "New Hampshire", abr: " NH" },
  { state: "Vermont", abr: "VT" },
  { state: "Hawaii", abr: "HI" },
  { state: "New Jersey", abr: "NJ" },
  { state: "Virginia", abr: "VA" },
  { state: "Idaho", abr: "ID" },
  { state: "New Mexico", abr: "NM" },
  { state: "Virgin Islands", abr: "VI" },
  { state: "Illinois", abr: "IL" },
  { state: "New York", abr: "NY" },
  { state: "Washington", abr: " WA" },
  { state: "Indiana", abr: " IN" },
  { state: "North Carolina", abr: " NC" },
  { state: "West Virginia", abr: "WV" },
  { state: "Iowa", abr: "IA" },
  { state: "North Dakota", abr: " ND" },
  { state: "Wisconsin", abr: " WI" },
  { state: "Kansas", abr: "KS" },
  { state: "Northern Mariana Islands", abr: "CM" },
  { state: "Wyoming", abr: "WY" },
];
