import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { LOCAL_API_URL, PROD_API_URL } from "./config/settings";



if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = LOCAL_API_URL;
} else {
  axios.defaults.baseURL = PROD_API_URL;
}
console.log("NODE_ENV => " + process.env.NODE_ENV)
console.log("BASEURL => " + axios.defaults.baseURL)

// console.log = () => { };
// console.warn = () => { };

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
