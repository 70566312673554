import React from "react";
import JobCard from "./components/JobCard";
import Loading from "../../../../components/Loading";

const JobList = ({ jobs, pagination, findJobs, isLoading }) => {
  
  const handleLoadMore = () => {
    console.log(pagination.skip, pagination.total);
    if (pagination.skip >= pagination.total) return;
    findJobs();
  };

  return (
    <>
      {jobs.map(job => (
        <JobCard job={job} key={job.jobId} />
      ))}
      <Loading isLoading={isLoading} />
      <div className='findJobs__loadMore'>
        <span
          onClick={handleLoadMore}
          style={{
            cursor: pagination.skip >= pagination.total ? "auto" : "pointer",
          }}
        >
          {pagination.skip >= pagination.total ? "No more jobs" : "Load more"}{" "}

        </span>
      </div>
    </>
  );
};

export default JobList;
