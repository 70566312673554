import { DefinedRange } from "react-date-range";
import { DateRange } from "react-date-range";
import { dateRangeArr } from "./dateRange.config";

const FilterDates = ({ startDate, endDate, onDateChange }) => {

    const range = [{
        startDate,
        endDate,
        key: "selection"
    }];

    return (

        <section className='findJobs__inputsBox__inputContainer__range'>
            <div className='findJobs__inputsBox__inputContainer__range__1'>
                <DefinedRange
                    onChange={item => onDateChange(item.selection)}
                    ranges={range}
                    staticRanges={dateRangeArr}
                />
            </div>
            <div className='findJobs__inputsBox__inputContainer__range__2'>
                <DateRange
                    editableDateInputs={true}
                    onChange={item => onDateChange(item.selection)}
                    moveRangeOnFirstSelection={false}
                    ranges={range}
                    minDate={new Date(2012, 0, 1)}
                    maxDate={new Date()}
                />
            </div>
        </section>
    );
}

export default FilterDates;