import axios from "axios";
import React, { useEffect } from "react";
import { Navigate } from "react-router";
import useAuthentication from "../../hooks/useAuthentication";

const PrivateRoute = ({ children }) => {
  const { authenticate } = useAuthentication();
  const user = authenticate();

  console.log(user);

  const authHandler = () => {
    if (user) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${user.token}`;
    }
  };

  useEffect(() => {
    authHandler();
  }, []);

  if (user) {
    return children;
  }
  return <Navigate to='/signin' />;
};

export default PrivateRoute;
