import React, { useEffect, useState } from "react";
import useJobsSearch from "./hooks/useJobSearch";
import JobList from "./components/JobList/JobList";
import SearchForm from "./components/SearchForm/SearchForm";
import PageInfo from "./components/Pager/PageInfo";
import MainNavigation from "../../components/Layout";
import Loading from "../../components/Loading";

const PageJobSearch = () => {

  const { searchJobs } = useJobsSearch();
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  // Filters
  const [filters, setFilters] = useState({
    what: "",
    where: "",
    startDate: new Date(new Date().setDate(new Date().getDate() - 3)),
    endDate: new Date(new Date().setDate(new Date().getDate() - 1)),
  });

  // Pagination
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 15,
    total: 0,
    page: 0,
  });

  useEffect(() => {
    console.log(pagination, "--");
  }, [pagination]);

  const handleSearchJobs = reset => {

    let states = {
      jobs,
      filters,
      pagination,
      setJobs,
      setPagination,
      setLoading,
    };

    searchJobs(reset, states);
  };

  return (
    <MainNavigation>
      <main className='findJobs'>
        <SearchForm
          filters={filters}
          setFilters={setFilters}
          findJobs={handleSearchJobs}
        />

        <Loading isLoading={loading} />

        {jobs.length > 0 && (
          <>
            <PageInfo pagination={pagination} />
            <JobList jobs={jobs}
              pagination={pagination}
              findJobs={handleSearchJobs}
              isLoading={loading} />
            <PageInfo pagination={pagination}

            />
          </>
        )}



        {!loading && jobs.length < 1 && pagination.page !== 0 && (
          <div className='loading'>
            <span>No results!</span>
          </div>
        )}
      </main>
    </MainNavigation>
  );
};

export default PageJobSearch;
