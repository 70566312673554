import React from "react";

const CFWButton = ({ title, onClick, classes, loading, style }) => {
  return (
    <button
      className={`cFWButton ${classes}`}
      onClick={onClick}
      style={{ ...style }}
    >
      {loading ? (
        <div className='loading' style={styles.loaderContainer}>
          <span class='loader loaderSM'></span>
        </div>
      ) : (
        title
      )}
    </button>
  );
};

const styles = {
  loaderContainer: {
    width: "100%",
    height: "100%",
    minHeight: "auto",
    padding: "0.25rem",
  },
};

export default CFWButton;
