import React, { useState } from "react";

const JobDesc = ({ text, limit = 200 }) => {
  const [showAll, setShowAll] = useState(false);
  //const limit = 400;
  return (
    <div className='findJobs__jobCard__box__body__description__container'>
      <div className='findJobs__jobCard__box__body__description__container__details text-center text-white text-base pt-3 font-normal pb-5'>
        {text.length > limit && limit !== 0 ? (
          <div>
            {showAll ? (
              <div>
                <p>{text}</p>
                <div className='collapseLink'>
                  <span
                    onClick={() => setShowAll(false)}
                    className='text-primary'
                  >
                    Read Less
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <p>{text.substring(0, limit).concat("...")}</p>
                <div className='collapseLink'>
                  <span
                    onClick={() => setShowAll(true)}
                    className='text-primary'
                  >
                    Read More
                  </span>
                </div>
              </div>
            )}
          </div>
        ) : (
          text
        )}
      </div>
    </div>
  );
};

export default JobDesc;
