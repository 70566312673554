import React from "react";
import { Route, Routes } from "react-router";
import { BrowserRouter } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import routesArr from "./routes";

const JobRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        {routesArr.map(routeItem => (
          <Route
            path={routeItem.path}
            element={
              routeItem.isPrivate ? (
                <PrivateRoute>
                  {" "}
                  <routeItem.component />
                </PrivateRoute>
              ) : (
                <routeItem.component />
              )
            }
            key={routeItem.path}
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
};

export default JobRouter;
