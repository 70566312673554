import useJobsAPI from "../../../hooks/useJobsAPI";

const useJobsSearch = () => {

  const { apiFetchJobs } = useJobsAPI();

  const searchJobs = async (reset, states) => {

    const {
      jobs,
      filters,
      pagination,
      setJobs,
      setPagination,
      setLoading,
    } = states;

    if (!filters.startDate) return alert("Please select a starting date!");

    setLoading(true);

    const resp = await apiFetchJobs(
      {
        what: filters.what,
        where: filters.where,
        startDate: filters.startDate,
        endDate: filters.endDate,
      },
      {
        skip: reset ? 0 : pagination.skip,
        limit: pagination.limit,
      }
    );

    if (resp) {
      //Update Job List
      setJobs(reset ? [...resp.results] : [...jobs, ...resp.results]);

      //Update Pagination
      setPagination({
        limit: pagination.limit,
        skip: reset ? pagination.limit : pagination.skip + pagination.limit,
        total: resp.total,
        page: reset ? 1 : pagination.page + 1,
      });
    }

    setLoading(false);
  };

  return { searchJobs };
};

export default useJobsSearch;
