import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const JobSalary = ({ text }) => {
    return (text &&
        (
            <span className='findJobs__jobCard__box__body__rates__rate'>
                <MonetizationOnIcon
                    sx={{ fontSize: "1rem", marginRight: ".1rem" }}
                />
                {text}
            </span>
        )
    )

}

export default JobSalary;