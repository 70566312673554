const FindJobsBtn = (props) => {
    return (
        <section className='findJobs__inputsBox__inputContainer'>
            <button variant='contained' className='findJobs__inputsBox__jobsButton'>
                Find Jobs
            </button>
        </section>
    );
}

export default FindJobsBtn;