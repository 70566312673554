import DownloadIcon from "@mui/icons-material/Download";

const downloadImage = (url) => {
    window.open(url, "_blank");
};

const JobScreenshot = ({ url }) => {

    return (url && (
        <div className='jobDetail__detail__image'>
            <img src={url} alt='' />
            <div className='jobDetail__detail__image__actions'>
                <DownloadIcon
                    sx={{
                        color: "#002171",
                        fontSize: "5rem",
                        cursor: "pointer",
                    }}
                    onClick={() => downloadImage(url)}
                />
            </div>
        </div>
    )
    )
}

export default JobScreenshot;