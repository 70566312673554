import AttachmentIcon from '@mui/icons-material/Attachment';
import { IconButton } from "@mui/material";

const JobDownload = ({ url }) => {

    const downloadHandler = () => { window.open(url, "_blank"); };

    return (
        <IconButton
            className='findJobs__jobCard__box__header__menuIcon'
            onClick={downloadHandler}>

            {url && (

                <AttachmentIcon
                    sx={{
                        color: "white",
                        fontSize: "3rem",
                        cursor: "pointer",
                        label: "Screenshot",
                    }}

                />

            )}
        </IconButton>
    );
}

export default JobDownload;