import React from "react";

import JobTitle from "../../../../../components/JobInfo/JobTitle";
import JobDownload from "../../../../../components/JobInfo/JobDownload";
import JobPosted from "../../../../../components/JobInfo/JobPosted"
import JobSalary from "../../../../../components/JobInfo/JobSalary"
import JobType from "../../../../../components/JobInfo/JobType"
import JobRoot from "../../../../../components/JobInfo/JobRoot"
import JobLocation from "../../../../../components/JobInfo/JobLocation"
import JobCompany from "../../../../../components/JobInfo/JobCompany";
import JobFound from "../../../../../components/JobInfo/JobFound";
import JobDesc from "../../../../../components/JobInfo/JobDesc";

const JobCard = ({ job }) => {


  return (
    <section className='findJobs__jobCard'>
      <div className='findJobs__jobCard__box'>
        <header className='findJobs__jobCard__box__header'>
          <JobTitle text={job?.title} jobId={job.jobId} />
          <JobDownload url={job?.screenshotUrl} />
        </header>
        <div className='findJobs__jobCard__box__body'>

          <JobCompany text={job?.company} />
          <JobLocation text={job?.location} />
          <JobRoot text={job?.rootName} url={job?.rootUrl} />
          <JobFound date={job?.createdAt} />


          {(job?.salary || job?.type) && (
            <div className='findJobs__jobCard__box__body__rates'>
              <JobSalary text={job?.salary} />
              <JobType text={job?.type} />
            </div>
          )}

          <div className='findJobs__jobCard__box__body__description'>
            <JobDesc text={job?.description} />
            <JobPosted date={job?.createdAt} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default JobCard;
