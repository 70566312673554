var timeFrom = (X) => {
    var date = new Date();
    return new Date(date.getTime() - (X * 24 * 60 * 60 * 1000));
}

export const dateRangeArr = [
    {
        label: 'Last Week',
        hasCustomRendering: false,
        range: () => ({
            startDate: timeFrom(7),
            endDate: new Date()
        }),
        isSelected() {
            return true;
        }
    },
    {
        label: '1 Week ago',
        hasCustomRendering: false,
        range: () => ({
            startDate: timeFrom(14),
            endDate: timeFrom(7),
        }),
        isSelected() {
            return true;
        }
    },
    {
        label: '2 Weeks ago',
        hasCustomRendering: false,
        range: () => ({
            startDate: timeFrom(21),
            endDate: timeFrom(14),
        }),
        isSelected() {
            return true;
        }
    },
    {
        label: '3 Weeks ago',
        hasCustomRendering: false,
        range: () => ({
            startDate: timeFrom(28),
            endDate: timeFrom(21),
        }),
        isSelected() {
            return true;
        }
    },
    {
        label: 'Last 30 days',
        hasCustomRendering: false,
        range: () => ({
            startDate: timeFrom(30),
            endDate: new Date()
        }),
        isSelected() {
            return true;
        }
    },
    {
        label: 'Last 60 days',
        hasCustomRendering: false,
        range: () => ({
            startDate: timeFrom(60),
            endDate: new Date()
        }),
        isSelected() {
            return true;
        }
    },
    {
        label: 'Last 90 days',
        hasCustomRendering: false,
        range: () => ({
            startDate: timeFrom(90),
            endDate: new Date()
        }),
        isSelected() {
            return true;
        }
    }
];
export const dateLabels = [];

const exportedObject = {
    customDateRanges: dateRangeArr,
    dateLabels
};

export default exportedObject