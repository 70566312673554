import LinkIcon from "@mui/icons-material/Link";

const JobRoot = ({ text, url }) => {
    return (<span className='jobDetail__detail__summary__item'>
        <LinkIcon sx={{ color: "white", fontSize: "2rem", marginRight: ".3rem" }} />
        <a href={url} target='_blank' rel="noreferrer">
            {text}
        </a>
    </span>);
}

export default JobRoot;