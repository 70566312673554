import React, { useState } from "react";
import CInput from "../../../components/Form/CInput";
import CFWButton from "../../../components/Form/CFWButton";
import { useNavigate, Link } from "react-router-dom";
import { Button } from "@mui/material";
import useAuthentication from "../../../hooks/useAuthentication";

const SignIn = () => {
  const navigate = useNavigate();
  const { login } = useAuthentication();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const handleChange = e => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    await login(user);
    setLoading(false);
  };

  return (
    <div className='authentication signin'>
      <div className='authentication__box' style={styles.boxStyle}>
        <div className='authentication__box__left'>
          <div>
            <h1>Sign In</h1>
            <form onSubmit={handleSubmit}>
              <CInput
                type='email'
                placeholder='Email'
                name='email'
                label='Email'
                onChange={handleChange}
                required={true}
              />
              <CInput
                type='password'
                name='password'
                placeholder='Password'
                label='Password'
                onChange={handleChange}
                required={true}
              />
              <CFWButton
                title='Sign In'
                loading={loading}
                classes='theme-btn'
              />
            </form>
          </div>
          <div className='authentication__box__left__mobile'>
            <span>Don't have an account?</span>
            <Link to='/signup'>Register Now!</Link>
          </div>
        </div>
        <div className='authentication__box__right'>
          <h1>The Job Vault</h1>
          <span>Don't have an account?</span>
          <Button onClick={() => navigate("/signup")}>Register Now!</Button>
          {/* <Link to='/signup'>Register Now!</Link> */}
        </div>
      </div>
    </div>
  );
};

const styles = {
  boxStyle: {
    maxHeight: "30rem",
  },
};

export default SignIn;
