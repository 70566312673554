import { useNavigate } from "react-router";

const JobTitle = ({ text, jobId }) => {
    const navigate = useNavigate();

    const gotoJobDetail = () => navigate(`/jobDetail/${jobId}`);

    return (
        <h2
            className='findJobs__jobCard__box__header__heading'
            onClick={gotoJobDetail}
        >
            <span>{text}</span>

        </h2>
    );
}

export default JobTitle;