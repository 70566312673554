import FactoryIcon from '@mui/icons-material/Factory';

const JobCompany = ({ text }) => {
    return (<span className='findJobs__jobCard__box__body__address'>
        <FactoryIcon
            sx={{ color: "white", fontSize: "2rem", marginRight: ".3rem" }}
        />
        {text}
    </span>);
}

export default JobCompany;