import { IconButton } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const FilterWhere = ({ text, onChange }) => {
    return (
        <section className='findJobs__inputsBox__inputContainer'>
            <label className='findJobs__inputsBox__inputContainer__label'>
                Where
            </label>
            <input
                type='text'
                placeholder='Location'
                className='findJobs__inputsBox__inputContainer__input'
                value={text}
                name='where'
                onChange={onChange}
            />
            <div className='findJobs__inputsBox__inputContainer__iconContainer'>
                <IconButton className='findJobs__inputsBox__inputContainer__iconContainer__icon'>
                    <LocationOnIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
            </div>
        </section>
    );
}

export default FilterWhere;