import SearchIcon from "@mui/icons-material/Search";
import { IconButton } from "@mui/material";

const FilterWhat = ({ text, onChange }) => {
    return (
        <section className='findJobs__inputsBox__inputContainer'>
            <label className='findJobs__inputsBox__inputContainer__label'>
                What
            </label>
            <input
                type='text'
                placeholder='Job titles, Keywords or Company'
                className='findJobs__inputsBox__inputContainer__input'
                value={text}
                name='what'
                onChange={onChange}
                required
            />
            <div className='findJobs__inputsBox__inputContainer__iconContainer'>
                <IconButton className='findJobs__inputsBox__inputContainer__iconContainer__icon'>
                    <SearchIcon sx={{ fontSize: "1.2rem", color: "black" }} />
                </IconButton>
            </div>
        </section>
    );
}

export default FilterWhat;