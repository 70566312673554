import SignIn from "../../pages/authentication/Signin";
import Signup from "../../pages/authentication/Signup";
import PageJobSearch from "../../pages/PageJobSearch/PageJobSearch";
import PageJobDetail from "../../pages/PageJobDetail";

const routesArr = [
  {
    name: "Register",
    path: "/signup",
    component: Signup,
    isPrivate: false,
  },
  {
    name: "Sign In",
    path: "/signin",
    component: SignIn,
    isPrivate: false,
  },
  {
    name: "Find Jobs",
    path: "/",
    component: PageJobSearch,
    isPrivate: false,
  },
  {
    name: "Find Jobs",
    path: "/jobDetail/:_job",
    component: PageJobDetail,
    isPrivate: true,
  },
];

export default routesArr;
