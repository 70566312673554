import React, { useEffect, useState } from "react";

import { useLocation } from "react-router";

import useJobsAPI from "../../hooks/useJobsAPI";
import MainNavigation from "../../components/Layout";
import JobScreenshot from "../../components/JobInfo/JobScreenshot"
import JobSalary from "../../components/JobInfo/JobSalary"
import JobType from "../../components/JobInfo/JobType"
import JobRoot from "../../components/JobInfo/JobRoot"
import JobLocation from "../../components/JobInfo/JobLocation"
import JobCompany from "../../components/JobInfo/JobCompany";
import JobFound from "../../components/JobInfo/JobFound";
import JobDesc from "../../components/JobInfo/JobDesc";

const PageJobDetail = () => {
  const location = useLocation();
  const { fetchJobById } = useJobsAPI();
  const [loading, setLoading] = useState(true);
  const [job, setJob] = useState();

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInitialData = async () => {
    let splitted = location.pathname.split("/");
    let jobId = splitted[splitted.length - 1];
    const response = await fetchJobById(jobId);
    console.log(response);
    if (response) {
      setJob({ ...response });
    }
    setLoading(false);
  };

  return (
    <MainNavigation>
      <div className='jobDetail'>
        {loading ? (
          <span class='loader'></span>
        ) : (
          <div className='jobDetail__detail'>
            <h2>{job?.title}</h2>
            <JobScreenshot url={job?.screenshotUrl} />

            <div className='findJobs__jobCard__box__body'>
              <JobCompany text={job?.company} />
              <JobLocation text={job?.location} />
              <JobRoot text={job?.rootName} url={job?.rootUrl} />
              <JobFound date={job?.createdAt} />
              <JobSalary text={job?.salary} />
              <JobType text={job?.type} />
            </div>

            <div className='jobDetail__detail__description'>
              <h3>Full Description</h3>
              <JobDesc text={job?.description} limit={0} />
            </div>
          </div>
        )}
      </div>
    </MainNavigation>
  );
};

export default PageJobDetail;
