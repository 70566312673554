import moment from "moment";

const JobPosted = ({ date }) => {
    return (
        <div className='findJobs__jobCard__box__body__description__postedTime'>
            <span>
                Posted {moment(date).startOf("day").fromNow()}{" "}
            </span>
        </div>
    )
}

export default JobPosted;