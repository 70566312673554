import moment from "moment";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

const JobFound = ({ date }) => {
    return (< span className='jobDetail__detail__summary__item' >
        <PendingActionsIcon sx={{ color: "white", fontSize: "2rem", marginRight: ".3rem" }} />
        <span>{moment(date).format("MMMM Do YYYY")}</span>
    </span >)
}

export default JobFound;