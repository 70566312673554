import React from "react";
import { useNavigate } from "react-router";
import { USER_STORAGE_KEY } from "../../config/settings";
import CButton from "../Form/CButton";
import PageLogo from "../PageLogo";


const MainNavigation = ({ children }) => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem(USER_STORAGE_KEY);
    navigate("/signin");
  };
  return (
    <div className='page'>

      <div className='page__header'>
        <CButton title='Logout' onClick={logout} />
      </div>
      <PageLogo />
      {children}
    </div>
  );
};

export default MainNavigation;
