const Loading = ({ isLoading }) => {
    return (
        isLoading && (
            <div className='loading'>
                <span className='loader'></span>
            </div>
        )
    );
}

export default Loading;