import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";

const JobType = ({ text }) => {
    return (
        text && (
            <span className='findJobs__jobCard__box__body__rates__rate'>
                <BusinessCenterIcon
                    sx={{ fontSize: "1rem", marginRight: ".1rem" }}
                />
                {text}
            </span>
        )
    )
}

export default JobType;